@each $color, $value in $theme-colors-5fpro {
  .btn-#{$color} {
    --#{$prefix}btn-color: var(--#{$prefix}#{$color}-color, #fff) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-bg: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-border-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-hover-color: var(--#{$prefix}#{$color}-color, #fff) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-hover-bg: color-mix(in oklab, var(--#{$prefix}#{$color}), #000 20%) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-hover-border-color: color-mix(in oklab, var(--#{$prefix}#{$color}), #000 20%) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$color}-rgb), .15 !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-color: var(--#{$prefix}btn-hover-color, #fff) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-bg: var(--#{$prefix}btn-hover-bg) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-border-color: var(--#{$prefix}btn-hover-border-color) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow} !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-disabled-color: var(--#{$prefix}btn-color) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-disabled-bg: var(--#{$prefix}btn-bg) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-disabled-border-color: var(--#{$prefix}btn-border-color) !important; // stylelint-disable-line declaration-no-important
  }

  .btn-outline-#{$color} {
    --#{$prefix}btn-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-border-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-hover-color: var(--#{$prefix}#{$color}-color, #fff) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$color}-color, #fff) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$color}-rgb) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-color: var(--#{$prefix}#{$color}-color, #fff) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-bg: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-border-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
    --#{$prefix}btn-disabled-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}btn-disabled-bg: transparent;
    --#{$prefix}btn-disabled-border-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    --#{$prefix}gradient: none;
  }
}
