//
// Parallax
// --------------------------------------------------


// Background image / video parallax
// based on https://github.com/nk-o/jarallax

.jarallax {
  position: relative;
  z-index: 0;
}
.jarallax .jarallax-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: {
    position: center;
    size: cover;
  }
}


// Disable element parallax on different breakpoints
/* stylelint-disable declaration-no-important */
[data-disable-parallax-down="xs"] {
  @include media-breakpoint-down(xs) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-down="sm"] {
  @include media-breakpoint-down(sm) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-down="md"] {
  @include media-breakpoint-down(md) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-down="lg"] {
  @include media-breakpoint-down(lg) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-up="sm"] {
  @include media-breakpoint-up(sm) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-up="md"] {
  @include media-breakpoint-up(md) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-up="lg"] {
  @include media-breakpoint-up(lg) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-up="xl"] {
  @include media-breakpoint-up(xl) {
    opacity: 1 !important;
    transform: none !important;
  }
}
/* stylelint-enable declaration-no-important */


// Cursor parallax
// based on https://github.com/wagerfield/parallax

.parallax {
  position: relative;
}
.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:first-child { position: relative; }

  > img {
    display: block;
    width: 100%;
  }
}
