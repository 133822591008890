$prefix: ar- !default;
$primary: #814590 !default;
$secondary: #3a2b84 !default;
$btn-secondary-color: #fff !default;
$success: #18c054 !default;
$success-dark: #18c054 !default;
$info: #1884c0 !default;
$light: #f5f5f5 !default;
$gray-light: #d2d2d2 !default;
$gray-normal: #7c7c7c !default;
$gray-dark: #3a3a3a !default;
$dark: #161616 !default;
$body-color: $dark;
$navbar-brand-font-size: 28px;
$navbar-brand-font-weight: 700;
$card-border-radius: var(--#{$prefix}border-radius-lg) !default;
$breadcrumb-hover-color: var(--#{$prefix}primary);
$breadcrumb-active-color: var(--#{$prefix}primary);
$pagination-hover-color: var(--#{$prefix}primary);
$form-feedback-valid-color: $success-dark !default;
$form-valid-color: $success-dark !default;
$form-valid-border-color: $success-dark !default;
$form-valid-color-dark: $success !default;
$form-valid-border-color-dark: $success !default;
$font-family-sans-serif: "Roboto Slab", "Noto Sans TC", sans-serif !default;
$font-family-serif: merriweather, "Noto Serif TC", serif !default;
$font-size-base: 1rem !default;
$link-decoration: none !default;

$gray-500: $gray-normal;
$gray-600: $gray-normal;
$component-active-bg: var(--#{$prefix}primary);

$focus-ring-opacity:    .25 !default;
$focus-ring-color:      rgba(var(--#{$prefix}primary-rgb), #{$focus-ring-opacity}) !default;

$input-focus-color: var(--#{$prefix}input-focus-color, inherit);
$input-focus-bg: var(--#{$prefix}input-focus-bg, inherit);
$input-focus-border-color: var(--#{$prefix}input-focus-border-color, var(--#{$prefix}primary));
