:root {
  --#{$prefix}footer-bg: transparent;
  --#{$prefix}footer-color: var(--#{$prefix}body-color);
  --#{$prefix}footer-link-color: var(--#{$prefix}link-color);
  --#{$prefix}footer-link-color-hover: var(--#{$prefix}link-hover-color);
}
footer {
  color: var(--#{$prefix}footer-color);
  background: var(--#{$prefix}footer-bg);
  .nav-link,
  .nav-link:visited,
  .link,
  .link:visited {
    color: var(--#{$prefix}footer-link-color);
    &:hover,
    &:focus,
    &:active {
      color: var(--#{$prefix}footer-link-color-hover);
    }
  }
}
