/* stylelint-disable selector-no-qualifying-type */

.breadcrumb {
  --#{$prefix}breadcrumb-item-active-color: var(--#{$prefix}primary);
  --#{$prefix}breadcrumb-item-hover-color: var(--#{$prefix}primary);
}


.bg-disabled {
  color: $gray-dark;
  background-color: $gray-light !important; // stylelint-disable-line declaration-no-important
}
.img-thumb-w100 {
  object-fit: cover;
  width: 100%;
}
.img-thumb-h100 {
  object-fit: cover;
  height: 100%;
}
footer {
  .copyright {
    padding-top: $font-size-base;
    border-top: 1px solid #{$gray-light};
  }
}

.grecaptcha-badge {
  display: none;
}

.pagination {
  .active > .page-link {
    z-index: 0;
  }
}
