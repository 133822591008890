:root {
  --#{$prefix}navbar-bg: #fff;
  --#{$prefix}navbar-bg-stuck: var(--#{$prefix}navbar-bg);
  --#{$prefix}navbar-border-bottom: 1px solid #{$gray-light};
  --#{$prefix}navbar-link-color: var(--#{$prefix}primary);
  --#{$prefix}navbar-link-color-hover: var(--#{$prefix}primary-darken);
  --#{$prefix}navbar-link-color-stuck: var(--#{$prefix}primary);
  --#{$prefix}navbar-link-color-hover-stuck: var(--#{$prefix}primary-darken);

  --#{$prefix}navbar-dropdown-color: var(--#{$prefix}navbar-link-color);
  --#{$prefix}navbar-dropdown-color-hover: var(--#{$prefix}navbar-link-color-hover);
  --#{$prefix}navbar-dropdown-bg: var(--#{$prefix}navbar-bg);
  --#{$prefix}navbar-dropdown-color-stuck: var(--#{$prefix}navbar-link-color-stuck);
  --#{$prefix}navbar-dropdown-color-hover-stuck: var(--#{$prefix}navbar-link-color-hover-stuck);
  --#{$prefix}navbar-dropdown-bg-stuck: var(--#{$prefix}navbar-bg-stuck);

  --#{$prefix}navbar-toggler-bgcolor: var(--#{$prefix}navbar-link-color);
  --#{$prefix}navbar-toggler-bgcolor-stuck: var(--#{$prefix}navbar-link-color-stuck);

  --#{$prefix}navbar_brand-color: var(--#{$prefix}navbar-link-color);
  --#{$prefix}navbar_brand-hover-color: var(--#{$prefix}navbar-link-color-hover);
  --#{$prefix}navbar_brand-color-stuck: var(--#{$prefix}navbar-link-color-stuck);
  --#{$prefix}navbar_brand-hover-color-stuck: var(--#{$prefix}navbar-link-color-hover-stuck);

  --#{$prefix}nav-link-font-size: var(--#{$prefix}font-size-md);
}

.navbar {
  --#{$prefix}navbar-collapse-bg: transparent;
  --#{$prefix}navbar-collapse-color: var(--#{$prefix}navbar-dropdown-color);
  --#{$prefix}navbar-color: var(--#{$prefix}navbar-link-color);
  --#{$prefix}navbar-hover-color: var(--#{$prefix}navbar-link-color-hover);
  --#{$prefix}navbar-active-color: var(--#{$prefix}navbar-link-color-hover);
  --#{$prefix}navbar-stuck-bg: var(--#{$prefix}navbar-bg-stuck);
  --#{$prefix}navbar-toggler-color: var(--#{$prefix}navbar-toggler-bgcolor);

  --#{$prefix}navbar-brand-color: var(--#{$prefix}navbar_brand-color);
  --#{$prefix}navbar-brand-hover-color: var(--#{$prefix}navbar_brand-hover-color);

  background: var(--#{$prefix}navbar-bg);
  border-bottom: var(--#{$prefix}navbar-border-bottom);

  .navbar-brand {
    color: var(--#{$prefix}navbar-brand-color);
    &:hover {
      color: var(--#{$prefix}navbar-brand-hover-color);
    }
    h1 {
      color: var(--#{$prefix}navbar-brand-color);
      &:hover {
        color: var(--#{$prefix}navbar-brand-hover-color);
      }
    }
  }

  &.navbar-stuck {
    color: var(--#{$prefix}navbar-link-color-stuck);
    .navbar-brand {
      color: var(--#{$prefix}navbar_brand-color-stuck);
      &:hover {
        color: var(--#{$prefix}navbar_brand-hover-color-stuck);
      }
    }
    .nav-item {

      &:hover > .nav-link:not(.disabled),
      .nav-link.show:not(.disabled) {
        color: var(--#{$prefix}navbar-link-color-hover-stuck);
      }

      &.active > .nav-link:not(.disabled) {
        color: var(--#{$prefix}navbar-link-color-hover-stuck);
      }
    }

    .dropdown:hover > .nav-link {
      color: var(--#{$prefix}navbar-link-color-hover-stuck);
    }
    .dropdown-menu {
      --#{$prefix}dropdown-color: var(--#{$prefix}navbar-dropdown-color-stuck);
      --#{$prefix}dropdown-bg: var(--#{$prefix}navbar-dropdown-bg-stuck);
      background: var(--#{$prefix}dropdown-bg);

      a.dropdown-item { // stylelint-disable-line selector-no-qualifying-type
        color: var(--#{$prefix}navbar-dropdown-color-stuck);

        &:visited {
          color: var(--#{$prefix}navbar-dropdown-color-stuck);
        }

        &:hover,
        &:active {
          color: var(--#{$prefix}navbar-dropdown-color-hover-stuck);
        }
      }
    }
    a.nav-link { // stylelint-disable-line selector-no-qualifying-type
      &,
      &:visited {
        color: var(--#{$prefix}navbar-link-color-stuck);
      }
      &:active,
      &:hover {
        color: var(--#{$prefix}navbar-link-color-hover-stuck);
      }
    }
    .navbar-toggler-icon {
      &,
      &::before,
      &::after {
        background-color: var(--#{$prefix}navbar-toggler-bgcolor-stuck);
      }
    }
    .stuck {
      display: block;
    }
    .stuck-none {
      display: none;
    }
  }
  .stuck {
    display: none;
  }

  h1 {
    color: var(--#{$prefix}navbar-brand-color);
  }

  @media (max-width: 576px) {
    border-bottom-width: 0;
  }

  .dropdown-menu {
    --#{$prefix}dropdown-color: var(--#{$prefix}navbar-dropdown-color);
    --#{$prefix}dropdown-bg: var(--#{$prefix}navbar-dropdown-bg);

    a.dropdown-item { // stylelint-disable-line selector-no-qualifying-type
      color: var(--#{$prefix}navbar-dropdown-color);
      &:visited {
        color: var(--#{$prefix}navbar-dropdown-color);
      }
      &:hover,
      &:active {
        color: var(--#{$prefix}navbar-dropdown-color-hover);
      }
    }
  }
}
