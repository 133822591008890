$darken-values: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%
);
$lighten-values: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%
);

$custom-colors-5fpro: (
  "gray": $gray-500,
  "white": #fff,
  "black": #000
);
$theme-colors-5fpro: map-merge($theme-colors, $custom-colors-5fpro);
:root {
  --#{$prefix}primary: #{$primary};
  --#{$prefix}primary-rgb: #{to-rgb($primary)};  // bootstrap5
  --#{$prefix}primary-color: #fff;
  --#{$prefix}secondary: #{$secondary};
  --#{$prefix}secondary-rgb: #{to-rgb($secondary)}; // bootstrap5
  --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

  --#{$prefix}body-color: #404040;
  --#{$prefix}heading-color: #404040;
  --#{$prefix}secondary-color: #{$btn-secondary-color}; // bootstrap5, secondary text color
  --#{$prefix}secondary-color-rgb: #{to-rgb($btn-secondary-color)}; // bootstrap5, secondary text color

  --#{$prefix}gray: #{$gray-500};
  --#{$prefix}gray-rgb: #{to-rgb($gray-500)};
  --#{$prefix}white: #fff;
  --#{$prefix}white-color: var(--#{$prefix}dark);
  --#{$prefix}white-rgb: 255, 255, 255;
  --#{$prefix}black: #000;
  --#{$prefix}black-color: #fff;
  --#{$prefix}black-rgb: 0, 0, 0;
  --#{$prefix}dark-color: var(--#{$prefix}light);
  --#{$prefix}dark-light: var(--#{$prefix}dark);

  --#{$prefix}primary-darken: color-mix(in oklab, var(--#{$prefix}primary), #000 30%);
  --#{$prefix}primary-lighten: color-mix(in oklab, var(--#{$prefix}primary), #fff 30%);

  --#{$prefix}secondary-bg: var(--#{$prefix}secondary);
  --#{$prefix}secondary-bg-rgb: var(--#{$prefix}secondary-rgb); // bootstrap5, secondary color
  --#{$prefix}secondary-lighten: color-mix(in oklab, var(--#{$prefix}secondary), #fff 30%);
  --#{$prefix}secondary-darken: color-mix(in oklab, var(--#{$prefix}secondary), #000 10%);

  // 以下都是 bootstrap5
  --#{$prefix}link-color: var(--#{$prefix}primary);
  --#{$prefix}link-color-rgb: var(--#{$prefix}primary-rgb);
  --#{$prefix}link-hover-color: var(--#{$prefix}primary-darken);
  --#{$prefix}code-color: #{$code-color};
  --#{$prefix}highlight-color: #{$mark-color};
  --#{$prefix}highlight-bg: #{$mark-bg};
  --#{$prefix}border-color: #{$border-color};
  --#{$prefix}focus-ring-color: var(--#{$prefix}primary-lighten-30);
  --#{$prefix}form-valid-color: #{$form-valid-color};
  --#{$prefix}form-valid-border-color: #{$form-valid-border-color};
  --#{$prefix}form-invalid-color: #{$form-invalid-color};
  --#{$prefix}form-invalid-border-color: #{$form-invalid-border-color};

  --#{$prefix}input-focus-color: var(--#{$prefix}body-color);
  --#{$prefix}input-focus-bg: var(--#{$prefix}body-bg);
  --#{$prefix}input-focus-border-color: var(--#{$prefix}gray-lighten-30);

  @each $value, $percentage in $darken-values {
    @each $color, $color-value in $theme-colors-5fpro {
      --#{$prefix}#{$color}-darken-#{$value}: color-mix(in oklab, var(--#{$prefix}#{$color}), #000 #{$percentage});
    }
  }

  @each $value, $percentage in $lighten-values {
    @each $color, $color-value in $theme-colors-5fpro {
      --#{$prefix}#{$color}-lighten-#{$value}: color-mix(in oklab, var(--#{$prefix}#{$color}), #fff #{$percentage});
    }
  }

  @each $color, $color-value in $theme-colors-5fpro {
    --#{$prefix}#{$color}-text-emphasis: var(--#{$prefix}#{$color}-darken-50);
    --#{$prefix}#{$color}-bg-subtle: var(--#{$prefix}#{$color}-lighten-50);
    --#{$prefix}#{$color}-border-subtle: var(--#{$prefix}#{$color}-lighten-75);
  }

  --#{$prefix}secondary-background-color: var(--#{$prefix}secondary);

  --#{$prefix}bg-primary-secondary-color: var(--#{$prefix}secondary-color);
}

@each $color, $_value in $theme-colors-5fpro {
  .text-bg-#{$color} {
    color: var(--#{$prefix}#{$color}-color, #fff) !important; // stylelint-disable-line declaration-no-important
    background-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
  }
  .link-#{$color} {
    color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    text-decoration-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
    &:hover,
    &:focus {
      color: var(--#{$prefix}#{$color}-darken-20) !important; // stylelint-disable-line declaration-no-important
      text-decoration-color: var(--#{$prefix}#{$color}-darken-20) !important; // stylelint-disable-line declaration-no-important
    }
  }
  .link-hover-#{$color} {
    &:hover,
    &:focus {
      color: var(--#{$prefix}#{$color}-darken-20) !important; // stylelint-disable-line declaration-no-important
      text-decoration-color: var(--#{$prefix}#{$color}-darken-20) !important; // stylelint-disable-line declaration-no-important
    }
  }
  .bg-#{$color} {
    background-color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
  }
  .text-#{$color} {
    color: var(--#{$prefix}#{$color}) !important; // stylelint-disable-line declaration-no-important
  }
  @each $value, $percentage in $darken-values {
    .text-#{$color}-darken-#{$value} {
      color: var(--#{$prefix}#{$color}-darken-#{$value});
    }
    .bg-#{$color}-darken-#{$value} {
      background-color: var(--#{$prefix}#{$color}-darken-#{$value});
    }
  }
  @each $value, $percentage in $lighten-values {
    .text-#{$color}-lighten-#{$value} {
      color: var(--#{$prefix}#{$color}-lighten-#{$value});
    }
    .bg-#{$color}-lighten-#{$value} {
      background-color: var(--#{$prefix}#{$color}-lighten-#{$value});
    }
  }
}

$gradient-colors: (
  "primary": var(--#{$prefix}primary),
  "secondary": var(--#{$prefix}secondary),
  "white": var(--#{$prefix}white),
  "light": var(--#{$prefix}light),
  "dark": var(--#{$prefix}dark),
  "black": var(--#{$prefix}black),
  "gray": var(--#{$prefix}gray),
  "self": var(--color)
);

$gradient-directions: (
  "to-bottom": "to bottom",
  "to-right": "to right",
  "to-top": "to top",
  "to-left": "to left",
  "to-bottom-left": "to bottom left",
  "to-bottom-right": "to bottom right",
  "to-top-left": "to top left",
  "to-top-right": "to top right"
);

@each $start-color, $start-value in $gradient-colors {
  @each $end-color, $end-value in $gradient-colors {
    @if $start-color != $end-color {
      @each $class-name, $direction in $gradient-directions {
        .bg-#{$start-color}-#{$end-color}-#{$class-name} {
          background: linear-gradient(#{$direction}, #{$start-value} 0%, #{$end-value} 100%);

          @if $start-color == "white" or $end-color == "white" {
            color: var(--#{$prefix}dark);
          }
          @else {
            color: var(--#{$prefix}white);
          }
        }
      }
    }
  }
}

$darken-values: (10: .9, 20: .8, 30: .7, 40: .6, 50: .5);
$lighten-values: (10: 1.1, 20: 1.2, 30: 1.3, 40: 1.4, 50: 1.5);

@each $percentage, $value in $darken-values {
  .darken-#{$percentage} {
    filter: brightness($value);
  }
}

@each $percentage, $value in $lighten-values {
  .lighten-#{$percentage} {
    filter: brightness($value);
  }
}
