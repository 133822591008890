:root {
  --#{$prefix}font-family-serif: merriweather, "Noto Serif TC", serif;
  --#{$prefix}font-sans-serif: "Roboto Slab", "Noto Sans TC", sans-serif;

  --#{$prefix}font-weight-heading-default: 700;
  --#{$prefix}font-weight-normal-default: 400;
  --#{$prefix}font-line-height-heading-default: 1.3;
  --#{$prefix}letter-spacing-heading-default: .4px;
  --#{$prefix}letter-spacing-default: .4px;

  --#{$prefix}font-size-heading: min(calc(1.525rem + 3.3vw), 4rem);
  --#{$prefix}font-weight-heading: var(--#{$prefix}font-weight-heading-default);
  --#{$prefix}font-line-height-heading: var(--#{$prefix}font-line-height-heading-default);
  --#{$prefix}letter-spacing-heading: var(--#{$prefix}letter-spacing-heading-default);
  --#{$prefix}font-size-heading-mobile: calc(1.625rem + 4.5vw);
  --#{$prefix}font-weight-heading-mobile: var(--#{$prefix}font-weight-heading);
  --#{$prefix}font-line-height-heading-mobile: var(--#{$prefix}font-line-height-heading-default);
  --#{$prefix}letter-spacing-heading-mobile: var(--#{$prefix}letter-spacing-heading);

  --#{$prefix}font-size-h1: min(calc(1.375rem + 1.5vw), 2.5rem);
  --#{$prefix}font-weight-h1: var(--#{$prefix}font-weight-heading-default);
  --#{$prefix}font-line-height-h1: var(--#{$prefix}font-line-height-heading-default);
  --#{$prefix}letter-spacing-h1: var(--#{$prefix}letter-spacing-heading-default);
  --#{$prefix}font-size-h1-mobile: calc(1.375rem + 1.5vw);
  --#{$prefix}font-weight-h1-mobile: var(--#{$prefix}font-weight-h1);
  --#{$prefix}font-line-height-h1-mobile: var(--#{$prefix}font-line-height-h1);
  --#{$prefix}letter-spacing-h1-mobile: var(--#{$prefix}letter-spacing-h1);

  --#{$prefix}font-size-h2: min(calc(1.325rem + .9vw), 2rem);
  --#{$prefix}font-weight-h2: var(--#{$prefix}font-weight-heading-default);
  --#{$prefix}font-line-height-h2: 1.6;
  --#{$prefix}letter-spacing-h2: var(--#{$prefix}letter-spacing-heading-default);
  --#{$prefix}font-size-h2-mobile: calc(1.325rem + .9vw);
  --#{$prefix}font-weight-h2-mobile: var(--#{$prefix}font-weight-h2);
  --#{$prefix}font-line-height-h2-mobile: var(--#{$prefix}font-line-height-h2);
  --#{$prefix}letter-spacing-h2-mobile: var(--#{$prefix}letter-spacing-h2);

  --#{$prefix}font-size-h3: min(calc(1.3rem + .6vw), 1.75rem);
  --#{$prefix}font-weight-h3: var(--#{$prefix}font-weight-heading-default);
  --#{$prefix}font-line-height-h3: 1.5;
  --#{$prefix}letter-spacing-h3: var(--#{$prefix}letter-spacing-heading-default);
  --#{$prefix}font-size-h3-mobile: calc(1.3rem + .6vw);
  --#{$prefix}font-weight-h3-mobile: var(--#{$prefix}font-weight-h3);
  --#{$prefix}font-line-height-h3-mobile: var(--#{$prefix}font-line-height-h3);
  --#{$prefix}letter-spacing-h3-mobile: var(--#{$prefix}letter-spacing-h3);

  --#{$prefix}font-size-h4: min(calc(1.3rem + .6vw), 1.5rem);
  --#{$prefix}font-weight-h4: var(--#{$prefix}font-weight-heading-default);
  --#{$prefix}font-line-height-h4: 1.5;
  --#{$prefix}letter-spacing-h4: var(--#{$prefix}letter-spacing-heading-default);
  --#{$prefix}font-size-h4-mobile: calc(1.275rem + .3vw);
  --#{$prefix}font-weight-h4-mobile: var(--#{$prefix}font-weight-h4);
  --#{$prefix}font-line-height-h4-mobile: var(--#{$prefix}font-line-height-h4);
  --#{$prefix}letter-spacing-h4-mobile: var(--#{$prefix}letter-spacing-h4);

  --#{$prefix}font-size-h5: 1.25rem;
  --#{$prefix}font-weight-h5: var(--#{$prefix}font-weight-heading-default);
  --#{$prefix}font-line-height-h5: 1.4;
  --#{$prefix}letter-spacing-h5: var(--#{$prefix}letter-spacing-heading-default);
  --#{$prefix}font-size-h5-mobile: 1.25rem;
  --#{$prefix}font-weight-h5-mobile: var(--#{$prefix}font-weight-h5);
  --#{$prefix}font-line-height-h5-mobile: var(--#{$prefix}font-line-height-h5);
  --#{$prefix}letter-spacing-h5-mobile: var(--#{$prefix}letter-spacing-h5);

  --#{$prefix}font-size-h6: 1rem;
  --#{$prefix}font-weight-h6: var(--#{$prefix}font-weight-heading-default);
  --#{$prefix}font-line-height-h6: 1.5;
  --#{$prefix}letter-spacing-h6: var(--#{$prefix}letter-spacing-default);
  --#{$prefix}font-size-h6-mobile: 1rem;
  --#{$prefix}font-weight-h6-mobile: var(--#{$prefix}font-weight-h6);
  --#{$prefix}font-line-height-h6-mobile: var(--#{$prefix}font-line-height-h6);
  --#{$prefix}letter-spacing-h6-mobile: var(--#{$prefix}letter-spacing-h6);

  --#{$prefix}font-size-xxxl: var(--#{$prefix}font-size-heading);
  --#{$prefix}font-weight-xxxl: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-xxxl: var(--#{$prefix}font-line-height-heading);
  --#{$prefix}letter-spacing-xxxl: var(--#{$prefix}letter-spacing-heading);
  --#{$prefix}font-size-xxxl-mobile: var(--#{$prefix}font-size-heading-mobile);
  --#{$prefix}font-weight-xxxl-mobile: var(--#{$prefix}font-weight-xxxl);
  --#{$prefix}font-line-height-xxxl-mobile: var(--#{$prefix}font-line-height-heading-mobile);
  --#{$prefix}letter-spacing-xxxl-mobile: var(--#{$prefix}letter-spacing-heading-mobile);

  --#{$prefix}font-size-xxl: var(--#{$prefix}font-size-h2);
  --#{$prefix}font-weight-xxl: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-xxl: var(--#{$prefix}font-line-height-h2);
  --#{$prefix}letter-spacing-xxl: var(--#{$prefix}letter-spacing-h2);
  --#{$prefix}font-size-xxl-mobile: var(--#{$prefix}font-size-h2-mobile);
  --#{$prefix}font-weight-xxl-mobile: var(--#{$prefix}font-weight-xxl);
  --#{$prefix}font-line-height-xxl-mobile: var(--#{$prefix}font-line-height-h2-mobile);
  --#{$prefix}letter-spacing-xxl-mobile: var(--#{$prefix}letter-spacing-h2-mobile);

  --#{$prefix}font-size-xl: var(--#{$prefix}font-size-h3);
  --#{$prefix}font-weight-xl: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-xl: var(--#{$prefix}font-line-height-h3);
  --#{$prefix}letter-spacing-xl: var(--#{$prefix}letter-spacing-h3);
  --#{$prefix}font-size-xl-mobile: var(--#{$prefix}font-size-h3-mobile);
  --#{$prefix}font-weight-xl-mobile: var(--#{$prefix}font-weight-xl);
  --#{$prefix}font-line-height-xl-mobile: var(--#{$prefix}font-line-height-h3-mobile);
  --#{$prefix}letter-spacing-xl-mobile: var(--#{$prefix}letter-spacing-h3-mobile);

  --#{$prefix}font-size-lg: var(--#{$prefix}font-size-h4);
  --#{$prefix}font-weight-lg: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-lg: var(--#{$prefix}font-line-height-h4);
  --#{$prefix}letter-spacing-lg: var(--#{$prefix}letter-spacing-h4);
  --#{$prefix}font-size-lg-mobile: var(--#{$prefix}font-size-h4-mobile);
  --#{$prefix}font-weight-lg-mobile: var(--#{$prefix}font-weight-lg);
  --#{$prefix}font-line-height-lg-mobile: var(--#{$prefix}font-line-height-h4-mobile);
  --#{$prefix}letter-spacing-lg-mobile: var(--#{$prefix}letter-spacing-h4-mobile);

  --#{$prefix}font-size-md: var(--#{$prefix}font-size-h6);
  --#{$prefix}font-weight-md: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-md: var(--#{$prefix}font-line-height-h6);
  --#{$prefix}letter-spacing-md: var(--#{$prefix}letter-spacing-h6);
  --#{$prefix}font-size-md-mobile: var(--#{$prefix}font-size-h6-mobile);
  --#{$prefix}font-weight-md-mobile: var(--#{$prefix}font-weight-md);
  --#{$prefix}font-line-height-md-mobile: var(--#{$prefix}font-line-height-h6-mobile);
  --#{$prefix}letter-spacing-md-mobile: var(--#{$prefix}letter-spacing-h6-mobile);

  --#{$prefix}font-size-sm: .875rem;
  --#{$prefix}font-weight-sm: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-sm: 1.6;
  --#{$prefix}letter-spacing-sm: var(--#{$prefix}letter-spacing-default);
  --#{$prefix}font-size-sm-mobile: var(--#{$prefix}font-size-sm);
  --#{$prefix}font-weight-sm-mobile: var(--#{$prefix}font-weight-sm);
  --#{$prefix}font-line-height-sm-mobile: var(--#{$prefix}font-line-height-sm);
  --#{$prefix}letter-spacing-sm-mobile: var(--#{$prefix}letter-spacing-sm);

  --#{$prefix}font-size-xs: .75rem;
  --#{$prefix}font-weight-xs: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-xs: 1.5;
  --#{$prefix}letter-spacing-xs: var(--#{$prefix}letter-spacing-default);
  --#{$prefix}font-size-xs-mobile: var(--#{$prefix}font-size-xs);
  --#{$prefix}font-weight-xs-mobile: var(--#{$prefix}font-weight-xs);
  --#{$prefix}font-line-height-xs-mobile: var(--#{$prefix}font-line-height-xs);
  --#{$prefix}letter-spacing-xs-mobile: var(--#{$prefix}letter-spacing-xs);

  --#{$prefix}font-size-xxs: .625rem;
  --#{$prefix}font-weight-xxs: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}font-line-height-xxs: 1.5;
  --#{$prefix}letter-spacing-xxs: var(--#{$prefix}letter-spacing-default);
  --#{$prefix}font-size-xxs-mobile: var(--#{$prefix}font-size-xxs);
  --#{$prefix}font-weight-xxs-mobile: var(--#{$prefix}font-weight-xxs);
  --#{$prefix}font-line-height-xxs-mobile: var(--#{$prefix}font-line-height-xxs);
  --#{$prefix}letter-spacing-xxs-mobile: var(--#{$prefix}letter-spacing-xxs);

  --#{$prefix}font-size-body: var(--#{$prefix}font-size-md);
  --#{$prefix}font-weight-body: var(--#{$prefix}font-weight-md);
  --#{$prefix}line-height-body: var(--#{$prefix}font-line-height-md);
  --#{$prefix}letter-spacing-body: var(--#{$prefix}letter-spacing-md);
  --#{$prefix}font-size-body-mobile: var(--#{$prefix}font-size-md-mobile);
  --#{$prefix}font-weight-body-mobile: var(--#{$prefix}font-weight-md-mobile);
  --#{$prefix}line-height-body-mobile: var(--#{$prefix}font-line-height-md-mobile);
  --#{$prefix}letter-spacing-body-mobile: var(--#{$prefix}letter-spacing-md-mobile);

  --#{$prefix}font-size-article-body: 1.125rem;
  --#{$prefix}font-weight-article-body: var(--#{$prefix}font-weight-normal-default);
  --#{$prefix}line-height-article-body: 2;
  --#{$prefix}letter-spacing-article-body: .6px;
  --#{$prefix}font-size-article-body-mobile: var(--#{$prefix}font-size-article-body);
  --#{$prefix}font-weight-article-body-mobile: var(--#{$prefix}font-weight-article-body);
  --#{$prefix}line-height-article-body-mobile: var(--#{$prefix}line-height-article-body);
  --#{$prefix}letter-spacing-article-body-mobile: var(--#{$prefix}letter-spacing-article-body);
}

.serif {
  font-family: var(--#{$prefix}font-family-serif);
}

$heading-sizes: (1, 2, 3, 4, 5, 6);

@each $size in $heading-sizes {
  h#{$size}, .h#{$size} {
    font-size: var(--#{$prefix}font-size-h#{$size}) !important; // stylelint-disable-line declaration-no-important
    font-weight: var(--#{$prefix}font-weight-h#{$size}) !important; // stylelint-disable-line declaration-no-important
    line-height: var(--#{$prefix}font-line-height-h#{$size}) !important; // stylelint-disable-line declaration-no-important
    letter-spacing: var(--#{$prefix}letter-spacing-h#{$size}) !important; // stylelint-disable-line declaration-no-important
  }
  .fs-#{$size} {
    font-size: var(--#{$prefix}font-size-h#{$size}) !important; // stylelint-disable-line declaration-no-important
    line-height: var(--#{$prefix}font-line-height-h#{$size}) !important; // stylelint-disable-line declaration-no-important
    letter-spacing: var(--#{$prefix}letter-spacing-h#{$size}) !important; // stylelint-disable-line declaration-no-important
  }
  @include media-breakpoint-down(md) {
    h#{$size}, .h#{$size} {
      font-size: var(--#{$prefix}font-size-h#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      font-weight: var(--#{$prefix}font-weight-h#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      line-height: var(--#{$prefix}font-line-height-h#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      letter-spacing: var(--#{$prefix}letter-spacing-h#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
    }
    .fs-#{$size} {
      font-size: var(--#{$prefix}font-size-h#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      line-height: var(--#{$prefix}font-line-height-h#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      letter-spacing: var(--#{$prefix}letter-spacing-h#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
    }
  }
}

$font-weights: (
  "500",
  "600",
  "700",
  "800",
  "900"
);

@each $weight in $font-weights {
  .fw-#{$weight} {
    font-weight: #{$weight} !important; // stylelint-disable-line declaration-no-important
  }
}

$font-sizes: (
  "xxs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
  "xxxl"
);

@each $size in $font-sizes {
  .text-#{$size} {
    font-size: var(--#{$prefix}font-size-#{$size}) !important; // stylelint-disable-line declaration-no-important
    font-weight: var(--#{$prefix}font-weight-#{$size}) !important; // stylelint-disable-line declaration-no-important
    line-height: var(--#{$prefix}font-line-height-#{$size}) !important; // stylelint-disable-line declaration-no-important
    letter-spacing: var(--#{$prefix}letter-spacing-#{$size}) !important; // stylelint-disable-line declaration-no-important
  }
  .fs-#{$size} {
    font-size: var(--#{$prefix}font-size-#{$size}) !important; // stylelint-disable-line declaration-no-important
    line-height: var(--#{$prefix}font-line-height-#{$size}) !important; // stylelint-disable-line declaration-no-important
    letter-spacing: var(--#{$prefix}letter-spacing-#{$size}) !important; // stylelint-disable-line declaration-no-important
  }

  @include media-breakpoint-down(md) {
    .text-#{$size} {
      font-size: var(--#{$prefix}font-size-#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      font-weight: var(--#{$prefix}font-weight-#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      line-height: var(--#{$prefix}font-line-height-#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      letter-spacing: var(--#{$prefix}letter-spacing-#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
    }
    .fs-#{$size} {
      font-size: var(--#{$prefix}font-size-#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      line-height: var(--#{$prefix}font-line-height-#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
      letter-spacing: var(--#{$prefix}letter-spacing-#{$size}-mobile) !important; // stylelint-disable-line declaration-no-important
    }
  }
}

body {
  font-size: var(--#{$prefix}font-size-body) !important; // stylelint-disable-line declaration-no-important
  font-weight: var(--#{$prefix}font-weight-body) !important; // stylelint-disable-line declaration-no-important
  line-height: var(--#{$prefix}line-height-body) !important; // stylelint-disable-line declaration-no-important
  letter-spacing: var(--#{$prefix}letter-spacing-body) !important; // stylelint-disable-line declaration-no-important
}
@include media-breakpoint-down(md) {
  body {
    font-size: var(--#{$prefix}font-size-body-mobile) !important; // stylelint-disable-line declaration-no-important
    font-weight: var(--#{$prefix}font-weight-body-mobile) !important; // stylelint-disable-line declaration-no-important
    line-height: var(--#{$prefix}line-height-body-mobile) !important; // stylelint-disable-line declaration-no-important
    letter-spacing: var(--#{$prefix}letter-spacing-body-mobile) !important; // stylelint-disable-line declaration-no-important
  }
}

article .body,
.arx-editor-frame .entry {
  font-family: inherit;
  font-size: var(--#{$prefix}font-size-article-body);
  font-weight: var(--#{$prefix}font-weight-article-body);
  line-height: var(--#{$prefix}line-height-article-body);
  letter-spacing: var(--#{$prefix}letter-spacing-article-body);
}

@include media-breakpoint-down(md) {
  article .body,
  .arx-editor-frame .entry {
    font-size: var(--#{$prefix}font-size-article-body-mobile);
    font-weight: var(--#{$prefix}font-weight-article-body-mobile);
    line-height: var(--#{$prefix}line-height-article-body-mobile);
    letter-spacing: var(--#{$prefix}letter-spacing-article-body-mobile);
  }
}
