//
// Pagination
// --------------------------------------------------


.pagination {
  margin-bottom: 0;
}
.page-item {
  font-weight: $pagination-font-weight;
}
